import { useState, useEffect, useRef } from "react";
import { Row, Col, FormControl } from "react-bootstrap";
import UiButton from "../../components/button";
import Loader from "../../components/loader";
import Web3 from "web3";
import {
  PrimaryIssueManager,
  SecuritiesFactory,
  contractAddress,
} from "@verified-network/verified-sdk";

import DateTimePicker from "react-datetime-picker";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import {
  SuccessToast,
  FailureToast,
  ToastOptions,
  countryDetails,
  getCountryName,
  networks,
} from "../../utils/constants";
import { toast } from "react-toastify";
import { shortAddress } from "../../components/common/CommonHeader";
import "react-toastify/dist/ReactToastify.css";
import {
  useNetworkOptions,
  useIntermediaryList,
} from "../../context/contractDataLoaderService";
import { useAccount, useClient, useConnectorClient } from "wagmi";
import {
  getProviderNetwork,
  getProvider,
  getWeb3,
} from "../../utils/helpers/networks";
import { providers } from "ethers";

function StartIssueModal(props) {
  const { address, chainId } = useAccount();
  const account = address;
  const { data: wagmiClient } = useConnectorClient({ chainId });
  const { chain, transport } = wagmiClient || { chain: null, transport: null };
  let network, provider;
  if (chain) {
    network = getProviderNetwork(chain);
  }
  if (network) {
    provider = getProvider(transport, network);
  }
  let signer, web3;
  if (provider && provider.getSigner && chainId && address) {
    signer = provider.getSigner(address);
    web3 = getWeb3(transport);
  }

  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [custodian, setCustodian] = useState(null);
  const [value, onChange] = useState(new Date());
  const [country, setCountry] = useState("");
  const [changeContent, setChangeContent] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const modalContent = useRef(null);
  const { data: networkOptions } = useNetworkOptions("issue");
  const { data: custodianList } = useIntermediaryList(country, "Custodian");

  let primaryIssueManagerContract, securitiesfactory;
  if (provider && provider.getSigner && chainId && address) {
    const BalancerPrimaryIssueManager =
      contractAddress[chainId].BalancerPrimaryIssueManager;
    if (BalancerPrimaryIssueManager) {
      primaryIssueManagerContract = new PrimaryIssueManager(
        provider.getSigner(address),
        BalancerPrimaryIssueManager,
        "balancer"
      );
    }
    if (contractAddress[chainId].SecuritiesFactory) {
      securitiesfactory = new SecuritiesFactory(
        provider.getSigner(address),
        contractAddress[chainId].SecuritiesFactory
      );
    }
  }
  const filteredCountries = countryDetails.filter((country) =>
    country.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const startIssue = async () => {
    let dateTime = Math.floor(value.getTime() / 1000).toString();
    dateTime = web3.utils.toWei(dateTime, "ether");
    setLoading(true);
    if (
      props?.data?.issueManager?.toLowerCase() === account.toLowerCase() &&
      custodian
    ) {
      try {
        const tx = await securitiesfactory.setCustodian(
          props.data.offered,
          props.data.owner,
          custodian,
          { gasLimit: "2000000" }
        );
        setLoading(false);
      } catch (err) {
        let error = { err };
        console.log(error);
        const transactionLink = `${networks[chainId].blockExplorerUrls[0]}/tx/${error.err.transactionHash}`;
        setLoading(false);
        toast.error(FailureToast(transactionLink), ToastOptions);
        return;
      }
    }
    setLoading(true);
    try {
      const tx = await primaryIssueManagerContract.issue(
        props.data.offered,
        dateTime,
        props.data.owner,
        networkOptions
      );
      if (tx.code === "ACTION_REJECTED") {
        toast.error("Transaction rejected by user!", ToastOptions);
      } else {
        const transactionLink = `${networks[chainId].blockExplorerUrls[0]}/tx/${tx.response.hash}`;
        toast.success(SuccessToast(transactionLink), ToastOptions);
        setChangeContent(true);
        modalContent.current.innerHTML = "";
      }
      setLoading(false);
    } catch (err) {
      let error = { err };
      console.log(error);
      const transactionLink = `${networks[chainId].blockExplorerUrls[0]}/tx/${error.err.transactionHash}`;
      setLoading(false);
      toast.error(FailureToast(transactionLink), ToastOptions);
    }
  };
  return (
    <>
      {loading ? <Loader /> : null}
      {changeContent && (
        <UiButton
          onClick={() => {
            props.onModalHide();
          }}
          buttonVariant="primary"
          buttonClass="SignUpButton flex-1 ml-0"
          buttonText="Click to close this form&nbsp;&rarr;"
          type="submit"
        />
      )}
      <div className="d-grid gap-2" ref={modalContent}>
        <Row className="ml-1 align-items-center">
          {/* {props?.data?.issueManager?.toLowerCase() ===
            account?.toLowerCase() && (
            <>
              <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
                <Col xs={{ span: 3, offset: 2 }} className="text-left">
                  <b>Country</b>
                </Col>
                <Col className="pl-0" xs={5}>
                  <DropdownButton
                    id="dropdown-basic-button"
                    title={
                      country
                        ? getCountryName(country, "fullList")
                        : "Select country"
                    }
                  >
                    <FormControl
                      type="text"
                      placeholder="Search country"
                      className="mx-3 my-2 w-auto"
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    {filteredCountries.map((country) => (
                      <Dropdown.Item
                        key={country.code}
                        onClick={() => setCountry(country.code)}
                      >
                        {country.name}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                </Col>
              </Row>
              <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
                <Col xs={{ span: 3, offset: 2 }} className="text-left">
                  <b>Custodian</b>
                </Col>
                <Col className="pl-0" xs={5}>
                  <DropdownButton
                    id="dropdown-basic-button"
                    title={
                      custodianList
                        ? custodianList.length
                          ? custodian
                            ? shortAddress(custodian)
                            : "Select custodian"
                          : "Select custodian"
                        : null
                    }
                  >
                    {custodianList ? (
                      custodianList.length ? (
                        custodianList?.map((manager) => (
                          <Dropdown.Item
                            key={manager.address}
                            onClick={(e) => setCustodian(manager.address)}
                          >
                            <b>{manager.name}</b>&nbsp;(
                            {shortAddress(manager.address)})
                          </Dropdown.Item>
                        ))
                      ) : (
                        <span className="p-2">
                          {country
                            ? "No custodian present"
                            : "Select a country"}
                        </span>
                      )
                    ) : null}
                  </DropdownButton>
                </Col>
              </Row>
            </>
          )} */}
          <>
            <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
              <Col xs={{ span: 3, offset: 2 }} className="text-left">
                <b>Country</b>
              </Col>
              <Col className="pl-0" xs={5}>
                <DropdownButton
                  id="dropdown-basic-button"
                  title={
                    country
                      ? getCountryName(country, "fullList")
                      : "Select country"
                  }
                >
                  <FormControl
                    type="text"
                    placeholder="Search country"
                    className="mx-3 my-2 w-auto"
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  {filteredCountries.map((country) => (
                    <Dropdown.Item
                      key={country.code}
                      onClick={() => setCountry(country.code)}
                    >
                      {country.name}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </Col>
            </Row>
            <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
              <Col xs={{ span: 3, offset: 2 }} className="text-left">
                <b>Custodian</b>
              </Col>
              <Col className="pl-0" xs={5}>
                <DropdownButton
                  id="dropdown-basic-button"
                  title={
                    custodianList
                      ? custodianList.length
                        ? custodian
                          ? shortAddress(custodian)
                          : "Select custodian"
                        : "Select custodian"
                      : null
                  }
                >
                  {custodianList ? (
                    custodianList.length ? (
                      custodianList?.map((manager) => (
                        <Dropdown.Item
                          key={manager.address}
                          onClick={(e) => setCustodian(manager.address)}
                        >
                          <b>{manager.name}</b>&nbsp;(
                          {shortAddress(manager.address)})
                        </Dropdown.Item>
                      ))
                    ) : (
                      <span className="p-2">
                        {country ? "No custodian present" : "Select a country"}
                      </span>
                    )
                  ) : null}
                </DropdownButton>
              </Col>
            </Row>
          </>
          <Row className="mx-0 mb-3 my-2 pl-0 width-100 align-items-center">
            <Col xs={{ span: 3, offset: 2 }} className="text-left">
              <b>Cutoff time</b>
            </Col>
            <Col className="pl-0" xs={5}>
              <DateTimePicker onChange={onChange} value={value} />
            </Col>
          </Row>
          <Row className="mx-0 my-2 pl-0 width-100 align-items-center">
            <Col className="pl-0">
              <UiButton
                onClick={() => {
                  startIssue();
                }}
                buttonVariant="primary"
                buttonClass="SignUpButton flex-1 ml-0"
                buttonText="Start"
                type="submit"
              />
            </Col>
          </Row>
        </Row>
      </div>
    </>
  );
}

export function StartIssue(props) {
  return <StartIssueModal {...props} />;
}
